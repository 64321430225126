import React from "react";
import PropTypes from "prop-types";

const Title = (props) => {
  const {
    titleText,
    lineColor = "#FE4066",
    lineWidth = "35%",
    margin = "auto",
    lineMargin = "0 auto",
    color,
    classname,
  } = props;
  return (
    <div style={{ width: "fit-content", margin: margin }}>
      <p className={classname}>{titleText}</p>
      <hr
        style={{
          margin: lineMargin,
          background: lineColor,
          height: "3px",
          border: "none",
          width: lineWidth,
        }}
      />
    </div>
  );
};

Title.propTypes = {
  titleText: PropTypes.string.isRequired,
};

export default Title;
