import React from "react";

const Card = props => {
  const { list, cardImg } = props;
  return (
    <div class="card">
      <div class="middle">
        <div class="front">
          {/* <img src={cardImg} alt={list.alt} /> */}
          {cardImg && React.cloneElement(
          cardImg
        )}
        </div>
        <div class="back">
          <div class="back-content middle">
            <div className="contentWrapper">
              <h2 className="title">Do you still remember?</h2>
              <p className="content">{list.content}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
