import React from "react";
import PropTypes from "prop-types";

import FrontEnd from "./FrontendContent";
import BackEnd from "./BackendContent";
import Graphic from "./GraphicContent";

const TabContent = (props) => {
  const { activeTab } = props;
  return (
    <div id="TabContent">
      <div className="content-inner">
        {activeTab === 1 && <FrontEnd />}
        {activeTab === 2 && <BackEnd />}
        {activeTab === 3 && <Graphic />}
      </div>
    </div>
  );
};

TabContent.propTypes = {};

export default TabContent;
