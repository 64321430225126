import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Typist from "react-typist";

import SideBar from "../layout/PortfolioNav";

import IconEmail from "../../assets/icons/email";
import IconWhatsapp from "../../assets/icons/whatsapp";
import IconPhone from "../../assets/icons/phone";

import IconLoading from "../../assets/img/portfolio/loading.gif";
import IconSpinner from "../../assets/img/portfolio/spinner.gif";

import { useIsMobile } from "../../hooks/useMobile";
import WithWow from "../../hooks/useWow";

import emailjs from "emailjs-com";

function Contact() {
  const isMobile = useIsMobile();

  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [requireMsg, setRequireMsg] = React.useState(false);

  const [formData, setFormData] = React.useState({
    visitorName: "",
    visitorEmail: "",
    visitorContent: "",
  });

  const handleSendEmail = async (content) => {
    if (formData.visitorName === "" && formData.visitorEmail === "") {
      setRequireMsg(true);
      return;
    } else {
      const service_id = "default_service";
      const template_id = "portfolio";
      setLoading(true);
      emailjs
        .send(service_id, template_id, content, "user_cnTLXahCy0S9RqQBv3Q55")
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            setSuccess(true);
            setError(false);
            setRequireMsg(false);
            setFormData({
              visitorName: "",
              visitorEmail: "",
              visitorContent: "",
            });
            setLoading(false);
          },
          (err) => {
            console.log("FAILED...", err);
            setSuccess(false);
            setError(true);
            setRequireMsg(false);
            setLoading(false);
          }
        );
    }
  };

  const handleFormDataOnChange = (name, value) => {
    const newData = { ...formData };
    newData[name] = value;
    setFormData(newData);
  };

  React.useEffect(() => {
    setSuccess(false);
    setError(false);
    setRequireMsg(false);
    setFormData({
      visitorName: "",
      visitorEmail: "",
      visitorContent: "",
    });
  }, []);

  return (
    <WithWow>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <SideBar isMobile={isMobile} />
        <div id="Contact">
          <div className="outer-wrapper">
            <div className="overlay">
              <div className="inner-wrapper">
                <div
                  className="contact-card wow fadeIn"
                  data-wow-offset="50"
                  data-wow-duration="1s"
                  data-wow-delay="0.5s"
                >
                  <div className="left-wrapper">
                    <p className="contact-info">Contact Info</p>
                    <div className="info-wrapper">
                      <Info
                        svgIcon={<IconEmail size={25} fill={"white"} />}
                        infoText={"kyleyaukh@gmail.com"}
                      />
                      <Info
                        svgIcon={<IconPhone size={25} fill={"white"} />}
                        infoText={"+852 93226787"}
                      />
                      <Info
                        svgIcon={<IconWhatsapp size={25} fill={"white"} />}
                        infoText={"+852 93226787"}
                      />
                    </div>
                  </div>
                  <div className="right-wrapper">
                    <div className="right-inner-wrapper">
                      <p className="thanks-text">
                        Thank you for reaching out. If you have any query,
                        please do not hesitate to fill in the contact form or
                        direct message to me.
                      </p>
                      <div className="form-wrapper">
                        <div className="form">
                          <input
                            type="text"
                            placeholder="Name"
                            name="visitorName"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                e.target.name,
                                e.target.value
                              )
                            }
                            value={formData.visitorName}
                          />
                          <input
                            type="email"
                            placeholder="Email"
                            name="visitorEmail"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                e.target.name,
                                e.target.value
                              )
                            }
                            value={formData.visitorEmail}
                          />
                          <textarea
                            placeholder="Your Comment"
                            rows={isMobile ? "3" : "5"}
                            name="visitorContent"
                            onChange={(e) =>
                              handleFormDataOnChange(
                                e.target.name,
                                e.target.value
                              )
                            }
                            value={formData.visitorContent}
                          ></textarea>
                        </div>
                        <button
                          className="send-button"
                          onClick={() => handleSendEmail(formData)}
                        >
                          {loading ? (
                            <img style={{ width: "24px" }} src={IconSpinner} />
                          ) : (
                            `Send`
                          )}
                        </button>
                      </div>
                      {success && (
                        <p className="success-msg">
                          Your enquiry sent successfully, thanks for your
                          message!
                        </p>
                      )}
                      {error && (
                        <p className="error-msg">
                          Some errors happened, please try to send again later!
                        </p>
                      )}
                      {requireMsg && (
                        <p className="error-msg">
                          Please fill in both name and email.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithWow>
  );
}

export const Info = (props) => {
  const { svgIcon, infoText } = props;
  return (
    <div className="info-item">
      {svgIcon}
      <span className="info-text">{infoText}</span>
    </div>
  );
};

export default Contact;
