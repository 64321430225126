import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "../layout/Carousel";
import Navbar from "../layout/Navbar";

//import image
import img1 from "../../assets/img/carousel1.JPG";
import img2 from "../../assets/img/carousel2.JPG";
import img3 from "../../assets/img/carousel3.JPG";
import img4 from "../../assets/img/carousel4.jpg";
import img5 from "../../assets/img/carousel5.jpg";
import img6 from "../../assets/img/carousel6.jpg";
import img7 from "../../assets/img/carousel7.jpg";
import img8 from "../../assets/img/carousel8.JPG";
import img9 from "../../assets/img/carousel9.jpg";
import img10 from "../../assets/img/carousel10.jpg";
import img11 from "../../assets/img/carousel11.JPG";

const carousel_set = [
  {
    title: "Aug 2012",
    content: "Hi, maybe you still don't know yet, you will be my lovely girl.",
    img: `${img1}`,
  },
  {
    title: "Jul 2016",
    content:
      "Hi, we known each other for four amazing years, you still don't know you will be my girl.",
    img: `${img2}`,
  },
  {
    title: "Dec 2016",
    content:
      "Hi, our hearts are getting closer, and you are going to be my girl.",
    img: `${img3}`,
  },
  {
    title: "Feb 2017",
    content:
      "Hi, went through all the obstacles, you finally be my lovely girl.",
    img: `${img4}`,
  },
  {
    title: "Dec 2017",
    content:
      "Hi, my lovely girl, longest ten months in my life, I am so happy to meet you in Japan.",
    img: `${img5}`,
  },
  {
    title: "Mar 2018",
    content: "Hi, my lovely girl, what a good memory in Australia.",
    img: `${img6}`,
  },
  {
    title: "Oct 2018",
    content:
      "Hi, my lovely girl, will we look that good when we marry each other?",
    img: `${img7}`,
  },
  {
    title: "Dec 2018",
    content:
      "Hi, my lovely girl, congratulation to your graduation of Master degree, I am so proud of you.",
    img: `${img8}`,
    color: "black",
  },
  {
    title: "Feb 2019",
    content:
      "Hi, my lovely girl, I am going to propose you, will you marry me?",
    img: `${img9}`,
    color: "black",
  },
  {
    title: "Apr 2019",
    content:
      "Hi, my lovely girl, you finally get a job, let's work hard together for our future.",
    img: `${img10}`,
  },
  {
    title: "Jul 2019",
    content:
      "Hi, my lovely girl, we are going to marry soon. I love you and will love you forever.",
    img: `${img11}`,
    color: "black",
  },
];

const Story = (props) => {
  const [didMount, setDidMount] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setDidMount(true));
  }, []);

  const styles = {
    loading: {
      transform: "translateX(-10%)",
      opacity: 0,
      transition: "all 2.5s ease-in",
    },
    loaded: {
      transform: "translateX(0%)",
      opacity: 1,
      transition: "all 2.5s",
      transitionDelay: "all 1s",
    },
  };
  return (
    <>
      <Navbar />
      <div
        style={{
          ...(!didMount ? styles.loading : styles.loaded),
        }}
      >
        <Carousel carousel_set={carousel_set} />
      </div>
    </>
  );
};

export default Story;
