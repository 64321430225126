import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link, withRouter } from "react-router-dom";

const PortfolioSideBar = (props) => {
  const { isActive, setIsActive } = props;

  return (
    <div id="Portfolio-side-bar" className={isActive ? "isOpen" : "isNotOpen"}>
      <ul>
        <li className="nav-item" onClick={setIsActive}>
          <Link to="/portfolio">Home</Link>
        </li>
        <li className="nav-item" onClick={setIsActive}>
          <Link to="/portfolio/contact">Contact</Link>
        </li>
      </ul>
    </div>
  );
};

export default withRouter(PortfolioSideBar);
