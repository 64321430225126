import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store";

import "./App.css";
import "animate.css/animate.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-sweet-progress/lib/style.css";
import "./assets/styles.scss";

import SideBar from "./components/layout/PortfolioNav";
import Navbar from "./components/layout/Navbar";

import Home from "./components/pages/Home";
import Gallery from "./components/pages/Gallery";
import Story from "./components/pages/Story";
import Portfolio from "./components/pages/Portfolio";
import About from "./components/pages/about/About";
import Contact from "./components/pages/Contact";
import Project from "./components/pages/portfolio/SingleProject";
import NoMatch from "./components/pages/portfolio/NoMatch";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Switch>
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/portfolio/contact" component={Contact} />
            {/* <Route exact path="/portfolio/:project" component={Project} /> */}

            {/* <Navbar /> */}
            <Route exact path="/kkgarden" component={Home} />
            <Route exact path="/kkgarden/gallery" component={Gallery} />
            <Route exact path="/kkgarden/story" component={Story} />
            <Route component={NoMatch} />

          </Switch>
        </Fragment>
      </Router >
    </Provider >
  );
}

export default App;
