import React from "react";
import PropTypes from "prop-types";

import ProgressItem from "./ProgressItem";
import IconReact from "../../../../assets/img/portfolio/react.png";
import IconVue from "../../../../assets/img/portfolio/vue.png";
import IconJquery from "../../../../assets/img/portfolio/jquery.png";

const FrontendContent = (props) => {
  return (
    <div className="skills-content">
      <ProgressItem stackIcon={IconReact} stackName={"React"} percentage={90} />
      <ProgressItem stackIcon={IconVue} stackName={"Vue"} percentage={60} />
      <ProgressItem
        stackIcon={IconJquery}
        stackName={"jQuery"}
        percentage={70}
      />
    </div>
  );
};

FrontendContent.propTypes = {};

export default FrontendContent;
