import React from "react";
import img1 from "../../assets/img/firstimg.JPG";
import img2 from "../../assets/img/secondimg.JPG";
import img3 from "../../assets/img/thirdimg.JPG";

import Navbar from "../layout/Navbar";

const Home = (props) => {
  const [didMount, setDidMount] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setDidMount(true));
  }, []);

  const styles = {
    loading: {
      transform: "translateX(-10%)",
      opacity: 0,
      transition: "all 2.5s ease-in",
    },
    loaded: {
      transform: "translateX(0%)",
      opacity: 1,
      transition: "all 2.5s",
      transitionDelay: "all 1s",
    },
  };
  return (
    <>
      <Navbar />
      <div
        id="Home"
        style={{
          ...styles.gallery,
          ...(!didMount ? styles.loading : styles.loaded),
        }}
      >
        <section className="split">
          <div className="screen">
            <div className="wrapper">
              <img src={img1} alt="" className="image" />
            </div>
            <div className="content">
              <h2>The Day</h2>
            </div>
          </div>
          <div className="screen">
            <img src={img2} alt="" className="image" />
            <div className="content">
              <h2>Fade Set</h2>
              <h2>Us To</h2>
            </div>
          </div>
          <div className="screen">
            <img src={img3} alt="" className="image" />

            <div className="content">
              <h2>Collide</h2>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
