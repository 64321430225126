import React from "react";
import PropTypes from "prop-types";

const TabItem = (props) => {
  const { tabText, onClick, svgIcon, active } = props;
  return (
    <div
      onClick={onClick}
      className="tab-items"
      style={{ borderBottom: active && "2px solid #fe4066" }}
    >
      <span className="tab-text">{tabText}</span>
      {svgIcon}
    </div>
  );
};

TabItem.propTypes = {};

export default TabItem;
