import React from "react";
import { withRouter, Link } from "react-router-dom";
import SideBar from "./PortfolioSideBar";

import { HamburgerSqueeze } from "react-animated-burgers";

import Icon from "../../assets/img/favicon.png";

const PortfolioNav = (props) => {
  const { isMobile, initialBlack } = props;
  const [isActive, setIsActive] = React.useState(false);
  const setToggle = () => {
    setIsActive((prevState) => !prevState);
  };
  return (
    <div>
      {isMobile ? (
        <>
          <HamburgerSqueeze
            isActive={isActive}
            toggleButton={setToggle}
            buttonColor="rgba(255,255,255,0.5)"
            barColor={
              "black"
            }
            buttonWidth={isMobile ? 30 : 40}
            buttonStyle={
              isMobile
                ? {
                  position: "fixed",
                  zIndex: "100",
                  top: 0,
                  left: 0,
                  outline: "none",
                }
                : {
                  outline: "none",
                }
            }
          />
          <SideBar isActive={isActive} setIsActive={setToggle} />
        </>
      ) : (
          <div id="Portfolio-nav">
            <div className="icon">
              <span style={{ fontWeight: 600 }} className="icon-y">
                KY
            </span>
            </div>
            <ul className="nav">
              <li className="nav-item">
                <Link to="/portfolio">
                  Home
              </Link>
              </li>
              <Link to="/portfolio/contact">
                <li className="nav-item">Contact</li>
              </Link>
            </ul>
          </div>
        )}
    </div>
  );
};

export default withRouter(PortfolioNav);
