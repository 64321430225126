import React from "react";
import Typist from "react-typist";

import IconFb from "../../assets/icons/facebook";
import IconInstagram from "../../assets/icons/instagram";
import IconLinkedIn from "../../assets/icons/linkedin";
import IconGithub from "../../assets/icons/github";

import LandingDesk from "../../assets/img/portfolio/desk.jpg";
import LandingLamp from "../../assets/img/portfolio/lamp.jpg";

import SideBar from "../layout/PortfolioNav";

import { useIsMobile } from "../../hooks/useMobile";

import About from "./portfolio/About";
import Skills from "./portfolio/Skills";
import Projects from "./portfolio/Projects";
import Footer from "./portfolio/Footer";

import Modal from "../layout/portfolio/modal";

import WithWow from "../../hooks/useWow";

function Portfolio() {
  const isMobile = useIsMobile();

  const [show, setShow] = React.useState(false);
  const [job, setJob] = React.useState({});

  const handleSocialMediaIconClick = (link) => window.open(link, "_blank");

  return (
    <WithWow>
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <SideBar isMobile={isMobile} />
          <div id="Portfolio" style={{ flex: 1 }}>
            <div className="landing">
              <div className="landing-box">
                <div
                  className="landing-text-box wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay="1s"
                >
                  <p className="upper-text">Kyle Yau - Frontend Developer</p>
                  <p
                    className="lower-text wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="1.5s"
                  >
                    Passionate in Front-End Development
                  </p>
                </div>
                <div
                  className="landing-icons-wrapper wow fadeInUp"
                  data-wow-duration="2s"
                  data-wow-delay="2s"
                >
                  <IconFb
                    onClick={() =>
                      handleSocialMediaIconClick(
                        "https://www.facebook.com/kai.kyle.1/"
                      )
                    }
                  />
                  <IconGithub
                    onClick={() =>
                      handleSocialMediaIconClick(
                        "https://github.com/kylekaikai24"
                      )
                    }
                  />
                  <IconLinkedIn
                    onClick={() =>
                      handleSocialMediaIconClick(
                        "https://www.linkedin.com/in/kin-ho-yau-877430162/"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <About />
        <Skills />
        <Projects setJob={setJob} setShow={setShow} />
        <Footer />

        {/* Modal for projects */}
        <Modal show={show} setShow={setShow} job={job} />
      </div>
    </WithWow>
  );
}

export default Portfolio;
