import React from "react";
import bgImage from "../../assets/img/gallerybg.jpg";

import Card from "../layout/Card";
import Navbar from "../layout/Navbar";

const cardlist = [
  {
    alt: "1",
    title: "Do you still remember?",
    content:
      "Cannot forget the feeling that I finally can meet you after a so so long period, and 3000km between us",
  },
  {
    alt: "2",
    title: "First",
    content:
      "Hugging you little cute is just one of the most comfortable things in the world",
  },
  {
    alt: "3",
    title: "First",
    content: "HOLY CRAP!!!!!!!!!!!!!!!!!!!!!!",
  },
  {
    alt: "4",
    title: "First",
    content: "Usa Usa Usa Usa Usa Usa Usa Usa Usa Usa Usa Usa Usa......",
  },
  {
    alt: "5",
    title: "First",
    content: "This is the my new ultimate, ice-cream killing lol",
  },
  {
    alt: "6",
    title: "First",
    content:
      "Our mind must be offlined at that time, why we went out in the blizzard in Hakodate?",
  },
  {
    alt: "7",
    title: "First",
    content:
      "Though it is cold and windy, snow in Hokkaido is just so beautiful and romantic, especailly when you are beside me",
  },
  {
    alt: "8",
    title: "First",
    content:
      "A cup of warm peach tea in the winter's day, it warms our hearts and spirits",
  },
  {
    alt: "9",
    title: "First",
    content:
      "FUCK THIS STUPID DOG !!!!!!!! Do you remember this fucking dog?????",
  },
  {
    alt: "10",
    title: "First",
    content:
      "We walked in the snow to this quite restaurant, to enjoy the show of Japan guitar, this restaurant is so good",
  },
  {
    alt: "11",
    title: "First",
    content: "Squit, Beer and ... a silly girl hahahaha",
  },
  {
    alt: "12",
    title: "First",
    content: "Who the hell are you? Gangster? Japan Mafia?",
  },
  {
    alt: "13",
    title: "First",
    content:
      "本当にすみませんでした…　私のせいです、許してください (Translation: This is my fault, I am so sorry, please forgive me)",
  },
  {
    alt: "14",
    title: "First",
    content:
      "Oh... our son... I missed him so much. Don't know how is he now...",
  },
  {
    alt: "15",
    title: "First",
    content:
      "Do you feel happy in Disneyland? It is much better than Hong Kong's one",
  },
  {
    alt: "16",
    title: "First",
    content: "Hi Little Bunny, where is your sexy custom?",
  },
  {
    alt: "17",
    title: "First",
    content: "睇我一掌打碎你個天靈蓋 !",
  },
  {
    alt: "18",
    title: "First",
    content:
      "What had you wish for at that time? Does it come true? I hope it does. (I forgot what I wished haha)",
  },
  {
    alt: "19",
    title: "First",
    content: "I am Gundam. If you piss me off, I will put a laser on your face",
  },
  {
    alt: "20",
    title: "First",
    content:
      "This one might be the only one shadow photo since we know each other...",
  },
  {
    alt: "21",
    title: "First",
    content:
      "God damn this drink... Do you still remember the taste? I will never drink this shit again",
  },
  {
    alt: "22",
    title: "First",
    content: "Delicious, Tasty, Juicy, Worthy, Arrrrrrrrrrrrrrrrrr...",
  },
  {
    alt: "23",
    title: "First",
    content:
      "My baby, you like so cute and pretty in Japanese traditional custom",
  },
  {
    alt: "24",
    title: "First",
    content:
      "Quack QuackQuack? QuackQuackQuack? QuackQuackQuackQuackQuackQuackQuackQuackQuack...",
  },
  {
    alt: "25",
    title: "First",
    content: "Excuse me? What are you doing?",
  },
  {
    alt: "26",
    title: "First",
    content:
      "Finally in Australia, this the one of the most cool photo I have taken. I am just good in photography.",
  },
  {
    alt: "27",
    title: "First",
    content: "The beach time is so relax and happy, want to go with you again",
  },
  {
    alt: "28",
    title: "First",
    content: "I love you so so so much, my darling",
  },
  {
    alt: "29",
    title: "First",
    content:
      "Ar..................... A big cup of cold beer is the medicine for hot weather",
  },
  {
    alt: "30",
    title: "First",
    content: "Good Bye Tasimania ~~~~~~~",
  },
];

const Gallery = (props) => {
  const [didMount, setDidMount] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setDidMount(true));
  }, []);

  const styles = {
    gallery: {
      backgroundImage: `url(${bgImage})`,
    },
    loading: {
      transform: "translateX(-10%)",
      opacity: 0,
      transition: "all 2s ease-in",
    },
    loaded: {
      transform: "translateX(0%)",
      opacity: 1,
      transition: "all 1s",
      transitionDelay: "all 1s",
    },
  };
  console.log(didMount);
  return (
    <>
      <Navbar />
      <div
        style={{
          ...styles.gallery,
          ...(!didMount ? styles.loading : styles.loaded),
        }}
        id="Gallery"
      >
        <div className="jumbotron">
          <h1>Our Gallery</h1>
          <p className="up">You have become my only STAR</p>
          <p className="down">I will follow where ever you are</p>
        </div>
        <div className="cardWrapper">
          {cardlist.map((x, i) => (
            <Card
              list={x}
              cardImg={
                <img
                  src={require(`../../assets/img/gallery/img${i + 1}.jpg`)}
                  alt={i + 1}
                />
              }
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Gallery;
