import React from "react";

import TabBar from "./skills/TabBar";
import TabContent from "./skills/TabContent";

const Skills = (props) => {
  const [activeTab, setActiveTab] = React.useState(1);

  return (
    <section id="Skills">
      <div
        className="skills-section-inner-wrapper wow fadeIn"
        data-wow-duration="2.5s"
      >
        <div
          className="inner wow fadeInUp"
          data-wow-duration="2s"
          data-wow-delay="2s"
        >
          <TabBar activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        <div
          className="inner wow fadeInUp"
          data-wow-delay="3s"
          data-wow-duration="2s"
        >
          <TabContent activeTab={activeTab} />
        </div>
      </div>
    </section>
  );
};

export default Skills;
