import React from "react";

import Slider from "react-slick";

import Title from "../../layout/portfolio/title";

const modalBody = (props) => {
  const { job } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div id="Modal-Body">
      <div className="inner-wrapper">
        <div className="left-wrapper">
          <div className="carousel-wrapper">
            <Slider {...settings}>
              {job.slickImg.length > 0 &&
                job.slickImg.map((item) => (
                  <div className="img-wrapper">
                    <img className="slick-img" src={item.img} />
                    <p className="img-description">{item.description}</p>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
        <div className="right-wrapper">
          <Title
            titleText={job.company}
            margin={0}
            lineMargin={0}
            classname={"modal-title"}
          />
          <p className="modal-description">{job.description}</p>
          <a href={job.link} target="_blank">{job.linkName}</a>
          <div className="modal-job-scope">
            <p className="job-scope">Job Scope</p>
            <ul>
              {job.scope.map((item) => (
                <li className="modal-list-item">{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default modalBody;
