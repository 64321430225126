import React from 'react'
import { Link, withRouter } from "react-router-dom";

import Flower from "../../../assets/img/nature-flower.png"

const NoMatch = () => {
    return (
        <div id="NoMatch">
            <img src={Flower} className="flower" />
            <div className="message">
                <p className="sorry">Sorry to let you down :'(</p>
                <p className="description">The page you try to visit is no longer exist.</p>
                <p className="description">
                    Maybe you can look at my{" "}
                    <Link to="/portfolio">
                        portfolio
                    </Link>
                    {" "}instead, I spent a lot of time on it :-)
                </p>
            </div>
        </div>
    )
}

export default withRouter(NoMatch)
