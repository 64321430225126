import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Typist from "react-typist";

import SideBar from "../../layout/PortfolioNav";
import Title from "../../layout/portfolio/title";

import { useIsMobile } from "../../../hooks/useMobile";

import AboutMe from "./AboutMe";

function Portfolio() {
  const isMobile = useIsMobile();

  const [isActive, setIsActive] = React.useState(false);

  return (
    <div>
      <SideBar isMobile={isMobile} initialBlack />
      <div style={{ width: "100%", margin: "0 auto" }}>
        <AboutMe />
      </div>
    </div>
  );
}

export default Portfolio;
