import React from "react";
import PropTypes from "prop-types";

import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";

const ProgressItem = (props) => {
  const { stackName, stackIcon, percentage } = props;
  const [percent, setPercent] = React.useState(0);
  React.useEffect(() => {
    setPercent(percentage);
  }, []);
  return (
    <div className="progress-item">
      <div className="stack-item">
        <div className="stack-img-wrapper">
          <img src={stackIcon}></img>
        </div>
        <div>
          <span className="stack-name">{stackName}</span>
        </div>
      </div>
      <Progress
        percent={percent}
        status="active"
        theme={{
          active: {
            color: "#fe4066",
          },
        }}
      />
    </div>
  );
};

ProgressItem.propTypes = {};

export default ProgressItem;
