import React from "react";
import PropTypes from "prop-types";

import TabItem from "./TabItem";

import IconFrontend from "../../../../assets/icons/frontend";
import IconBackend from "../../../../assets/icons/backend";
import IconGraphic from "../../../../assets/icons/grahpic";

const TabBar = (props) => {
  const { activeTab, setActiveTab } = props;
  return (
    <div id="Tabbar">
      <TabItem
        tabText={"Front-End"}
        svgIcon={<IconFrontend size={24} />}
        active={activeTab === 1 && true}
        onClick={() => setActiveTab(1)}
      />
      <TabItem
        tabText={"Back-End"}
        svgIcon={<IconBackend size={24} />}
        active={activeTab === 2 && true}
        onClick={() => setActiveTab(2)}
      />
      <TabItem
        tabText={"Graphic"}
        svgIcon={<IconGraphic size={24} />}
        active={activeTab === 3 && true}
        onClick={() => setActiveTab(3)}
      />
    </div>
  );
};

TabBar.propTypes = {};

export default TabBar;
