import ahc01gif from "../img/portfolio/ahc/landing.gif";
import ahc02gif from "../img/portfolio/ahc/event.gif";
import ahc03gif from "../img/portfolio/ahc/job-search.gif";
import ahc04gif from "../img/portfolio/ahc/job-detail.gif";
import ahc05gif from "../img/portfolio/ahc/article.gif";
import ahc06gif from "../img/portfolio/ahc/js-account.gif";

import base01 from "../img/portfolio/familybase/login.png";
import base02 from "../img/portfolio/familybase/landing.png";
import base03 from "../img/portfolio/familybase/active.png";
import base04 from "../img/portfolio/familybase/admin.png";

export const jobs = {
  ahc: {
    shortName: "ahc",
    slickImg: [
      {
        img: ahc01gif,
        description: "Landing Page",
      },
      {
        img: ahc02gif,
        description: "Event, Employer Search & About Us",
      },
      {
        img: ahc03gif,
        description: "Search for different jobs",
      },
      {
        img: ahc04gif,
        description: "Job Detail Page",
      },
      {
        img: ahc05gif,
        description: "Article Page",
      },
      {
        img: ahc06gif,
        description: "Job-seeker Account Profile Page",
      },
    ],
    company: "Asia Hospitality Careers",
    description:
      "AsiaHospitalityCareers.com is a job portal aimed at helping job seekers and employers in hospitality industries, allowing employers to post recuritment advertisement, and job seekers can apply for their target job. Articles and news regarding hospitality can also be found on the site.",
    link: "https://www.asiahospitalitycareers.com/hk/en/#/",
    linkName: "AsiaHospitalityCareers >>",
    scope: [
      "Revamp legacy site to current site",
      "Refactor old style code to modern frontend code",
      "Create page for single event",
      "Work with third party vendor",
      "Backend Migration",
    ],
  },
  familybase: {
    shortName: "familybase",
    slickImg: [
      {
        img: base01,
        description: "Login Page",
      },
      {
        img: base02,
        description: "Landing Page - display all the users info",
      },
      {
        img: base03,
        description: "Individual info",
      },
      {
        img: base04,
        description: "Admin Panel",
      },
    ],
    company: "Family Base",
    description:
      "FamilyBase is a self-project, the site is build to gather family members information for them to have convenience access which they need to use those data.",
    link: "",
    linkName: "",
    scope: [
      "Stack: MongoDB, ExpressJS, ReactJS, NodeJS",
      "Include login system created by jwt",
      "Normal user can read the info, Admin has authority to edit the data",
      "Selenium to autofill information to apply taiwan travel visa was once implemented",
    ],
  },
};
