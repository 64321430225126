import React from "react";
import PropTypes from "prop-types";

import ProgressItem from "./ProgressItem";

import IconNode from "../../../../assets/img/portfolio/node.png";
import IconMongo from "../../../../assets/img/portfolio/mongo.png";

const BackendContent = (props) => {
  return (
    <div className="skills-content">
      <ProgressItem stackIcon={IconNode} stackName={"Node"} percentage={90} />
      <ProgressItem
        stackIcon={IconMongo}
        stackName={"MongoDB"}
        percentage={60}
      />
    </div>
  );
};

BackendContent.propTypes = {};

export default BackendContent;
