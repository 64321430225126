import React from "react";

import Title from "../../layout/portfolio/title";

import ProfileImg from "../../../assets/img/portfolio/profile-1.png";

const About = () => {
  return (
    <section id="About">
      <div
        className="title-background wow fadeIn"
        data-wow-duration="1.5s"
        data-wow-offset="50"
      >
        <p className="title">Hello! Nice To Meet You!</p>
      </div>
      <div className="photo-section">
        <div className="inner-wrapper">
          <div className="left-wrapper">
            <div
              className="photo-wrapper wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="1s"
            >
              <img className="profile-photo" src={ProfileImg}></img>
            </div>
          </div>
          <div
            className="right-wrapper wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="1.5s"
          >
            <Title
              lineMargin={0}
              lineWidth={"45%"}
              margin={0}
              titleText={"ABOUT ME"}
              classname="about-me"
            />
            <p className="about-me-text">
              As a late programming starter, I started my programming journey
              after my university graduation, but with a lot of passion and
              hardwork, I am now equipped frontend development skills like
              ReactJS, VueJS, JQuery, and with some simple knowledge of NodeJS
              and MongoDB, plus basic video and photo / graphic editing skills.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
