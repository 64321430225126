import React from "react";
import { withRouter, Link } from "react-router-dom";

import IconSearch from "../../../../assets/icons/search";

import { useIsMobile } from "../../../../hooks/useMobile";

const Project = (props) => {
  const { projectLogo, onClick, projectName } = props;
  const isMobile = useIsMobile();

  return (
    <>
      {/* {
        isMobile ? (
          <Link to={`/portfolio/${projectName}`}>
            <div className="project-card" onClick={onClick}>
              <img className="project-logo" src={projectLogo} />
              <div className="overlay">
                <IconSearch size={40} />
              </div>
            </div>
          </Link>
        ) : ( */}
      <div className="project-card" onClick={onClick}>
        <img className="project-logo" src={projectLogo} />
        <div className="overlay">
          <IconSearch size={40} />
        </div>
      </div>
      {/* )
      } */}
    </>
  );
};


export default withRouter(Project);
