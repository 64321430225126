import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import song from "../../assets/audio/perfect.mp3";

const Navbar = props => {
  const [isOpen, setIsOpen] = useState(false);
  const closeNav = () => setIsOpen(false);

  const [isPlay, setIsPlay] = useState(false);
  const playAudio = () => {
    let audio = document.getElementById("song");
    setIsPlay(!isPlay);
    if (!isPlay) {
      audio.volume = 0.5
      audio.play();
    } else {
      audio.pause();
    }
  }

  return (
    <div id="Navbar">
      <i
        class="fas fa-bars"
        onClick={() => {
          setIsOpen(!isOpen);
          console.log(isOpen);
        }}
      />
      <div className={`${isOpen && "overlay-fadein"} overlay`}>
        <div className={`${isOpen && "navbar-fadein"} navbar`}>
          <ul>
            <li className="nav-item" onClick={() => closeNav()}>
              <Link to="/kkgarden">Home</Link>
            </li>
            {/* <li className="nav-item" onClick={()=>closeNav()}>
              <Link to="#">About Us</Link>
            </li> */}
            <li className="nav-item" onClick={() => closeNav()}>
              <Link to="/kkgarden/gallery">Gallery</Link>
            </li>
            <li className="nav-item" onClick={() => closeNav()}>
              <Link to="/kkgarden/story">Our Story</Link>
            </li>
            <li className="nav-item">
              <audio src={song} id="song"></audio>
              {!isPlay ? (
                <i class="fas fa-play-circle audioBtn" onClick={() => { playAudio() }}></i>
              ) : (
                  <i class="fas fa-pause-circle audioBtn" onClick={() => { playAudio() }}></i>
                )
              }
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Navbar);
