import React from "react";
import PropTypes from "prop-types";
import ProgressItem from "./ProgressItem";

import IconPr from "../../../../assets/img/portfolio/pr.png";
import IconAi from "../../../../assets/img/portfolio/ai.png";

const GraphicContent = (props) => {
  return (
    <div className="skills-content">
      <ProgressItem stackIcon={IconPr} stackName={"AdobePr"} percentage={80} />
      <ProgressItem stackIcon={IconAi} stackName={"AdobeAI"} percentage={50} />
    </div>
  );
};

GraphicContent.propTypes = {};

export default GraphicContent;
