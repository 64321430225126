import React from 'react'
import Logo from "../../../assets/img/portfolio/logo.png"
const Footer = props => {
    return (
        <div id="Footer">
            <div className="inner-wrapper">
                <div className="icon-wrapper">
                    <img src={Logo} alt="" className="icon" />
                </div>
                <p className="copy-right">
                    &copy; 2020 KYLE YAU
                </p>
            </div>
        </div>
    )
}

export default Footer
