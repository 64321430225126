import React from "react";
import { Carousel } from "react-responsive-carousel";

const Carouselc = props => {
  const { carousel_set } = props;
  const [list, setList] = React.useState(carousel_set);

  const carousel_list = list.map((x, i) => (
    <div className="imgBox">
      <img src={x.img} alt="" className="carouselImage" />
      <div
        className="carouselContent"
        style={{ color: `${x.color ? x.color : "white"}` }}
      >
        <h1 className="contentTitle">{x.title}</h1>
        <p className="content">{x.content}</p>
        {i === list.length - 1 && <p className="content">By Your Boy</p>}
      </div>
    </div>
  ));

  return (
    <div id="Carousel">
      <Carousel
        showIndicators={false}
        autoPlay
        infiniteLoop
        interval={7000}
        width="100%"
        showThumbs={false}
        transitionTime={1000}
        showStatus={false}
      >
        {carousel_list}
      </Carousel>
    </div>
  );
};

export default Carouselc;
