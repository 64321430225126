import React from "react";

import Modal from "react-bootstrap/Modal";
import ModalBody from "./modalBody";

const modal = (props) => {
  const { show, setShow, job } = props;
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      centered
      scrollable
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <ModalBody job={job} />
      </Modal.Body>
    </Modal>
  );
};

export default modal;
