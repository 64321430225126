import React from "react";

const IconFacebook = ({ color, onClick }) => {
  return (
    <div onClick={onClick} className="social-media-icon">
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        className="svg-icon"
        viewBox="0 0 24 24"
      >
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 10h-2v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667h1.442v-2.5h-2.404c-1.798 0-2.596.792-2.596 2.308v1.692z" />
      </svg> */}
      <svg
        width="40"
        height="40"
        viewBox="0 0 1792 1792"
        xmlns="http://www.w3.org/2000/svg"
        fill="black"
      >
        <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"></path>
      </svg>
    </div>
  );
};

export default IconFacebook;
