import React from "react";
import PropTypes from "prop-types";

import Title from "../../layout/portfolio/title";

import Project from "./projects/project";

import ahcLogo from "../../../assets/img/portfolio/ahc.png";
import baseLogo from "../../../assets/img/portfolio/familybase.png";

import ScrollAnimation from "react-animate-on-scroll";

import { jobs } from "../../../assets/data/jobs";

const Projects = (props) => {
  const { setJob, setShow } = props;

  const setShowModalAndProject = (project) => {
    setJob(project);
    setShow(true);
  };
  return (
    <section id="Projects">
      <div className="outer-wrapper">
        <div className="inner-wrapper">
          <div className="wow fadeIn" data-wow-duration="2s">
            <Title
              titleText={"MY PROJECTS"}
              classname={"project-section-title"}
            />
          </div>
          <div className="project-cards-wrapper">
            <div
              className="wow fadeInUp"
              data-wow-delay="1s"
              data-wow-duration="2s"
            >
              <Project
                projectLogo={ahcLogo}
                onClick={() => setShowModalAndProject(jobs.ahc)}
                projectName={jobs.ahc.shortName}
              />
            </div>
            <div
              className="wow fadeInUp"
              data-wow-delay="1.5s"
              data-wow-duration="2s"
            >
              <Project
                projectLogo={baseLogo}
                onClick={() => setShowModalAndProject(jobs.familybase)}
                projectName={jobs.familybase.shortName}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Projects.propTypes = {};

export default Projects;
